import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import React from 'react';

const AnonymousUserNavbar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="hidden lg:flex lg:gap-x-12">
          {/* Home */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/home">{t('Home')}</Link>

          {/* Articles */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/published-articles">{t('Articles')}</Link>

          {/* Videos */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/videos">{t('Videos')}</Link>

          {/* About */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/about">{t('About')}</Link>

          {/* Contact */}
          <Link className="text-sm font-semibold leading-6 text-gray-900" data-te-nav-link-ref data-te-ripple-init data-te-ripple-color="light" to="/contact">{t('Contact')}</Link>

        </div>
      </nav>
    </div>
  )
};

export default AnonymousUserNavbar;
